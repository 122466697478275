
@font-face {
    font-family: 'Mulish-Regular';
    src: url('assets/fonts/Mulish-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'Mulish-Medium';
    src: url('assets/fonts/Mulish-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'Mulish-SemiBold';
    src: url('assets/fonts/Mulish-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'Mulish-Bold';
    src: url('assets/fonts/Mulish-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'Oswald-Regular';
    src: url('assets/fonts/Oswald-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'Oswald-Medium';
    src: url('assets/fonts/Oswald-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'Oswald-SemiBold';
    src: url('assets/fonts/Oswald-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'Oswald-Bold';
    src: url('assets/fonts/Oswald-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'SpaceMono-Regular';
    src: url('assets/fonts/SpaceMono-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal
}

html, body {
    height: 100%;
}

#root {
    height: 100%;
    display: flex;
}